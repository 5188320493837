@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .rainbow-border1 {
    --tw-gradient-stops: 0%, 16.66%, 33.33%, 50%, 66.66%, 83.33%, 100%;
    border-image: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet);
    border-image-slice: 1;
  }
  .rainbow-border2 {
    --tw-gradient-stops: 0%, 16.66%, 33.33%, 50%, 66.66%, 83.33%, 100%;
    border-image: linear-gradient(0deg, red, orange, yellow, green, blue, indigo, violet);
    border-image-slice: 1;
  }
}